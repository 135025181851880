import React from "react"
import "../global.css"
import { graphql, useStaticQuery } from "gatsby"
import HeadMetadata from "../components/HeadMetadata"
import HeroDesarrollos from "../desarrollos/HeroDesarrolloSinLogo"
import Layout from "../layout/Layout"
import CallToAction from '../layout/CallToActionC'
import Img from "gatsby-image"

export default () => {
  const img = useStaticQuery(graphql`
    {
      fondoOcelot: file(relativePath: { eq: "tapirus1.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      fondoOcelotSmall: file(relativePath: { eq: "tapirusVer1.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }   
      Img01: file(relativePath: { eq: "tapirus2.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img01Small: file(relativePath: { eq: "tapirusVer2.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } } 
      Img02: file(relativePath: { eq: "tapirus3.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img02Small: file(relativePath: { eq: "tapirusVer3.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img03: file(relativePath: { eq: "tapirus4.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img03Small: file(relativePath: { eq: "tapirusVer4.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img04: file(relativePath: { eq: "tapirus5.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img04Small: file(relativePath: { eq: "tapirusVer5.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }  
      Img05: file(relativePath: { eq: "tapirus6.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img05Small: file(relativePath: { eq: "tapirusVer6.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img06: file(relativePath: { eq: "tapirus7.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img06Small: file(relativePath: { eq: "tapirusVer7.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img07: file(relativePath: { eq: "tapirus8.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img07Small: file(relativePath: { eq: "tapirusVer8.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } } 
      Img08: file(relativePath: { eq: "tapirus9.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img08Small: file(relativePath: { eq: "tapirusVer9.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img09: file(relativePath: { eq: "tapirus10.png" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img09Small: file(relativePath: { eq: "tapirusVer10.png" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } } 
      Img10: file(relativePath: { eq: "tapirus11.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      Img10Small: file(relativePath: { eq: "tapirusVer11.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } } 
        
      Img11: file(relativePath: { eq: "tapirus15.jpg" }) { sharp: childImageSharp {
         fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
       Img11Small: file(relativePath: { eq: "tapirusVer15.jpg" }) { sharp: childImageSharp {
         fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }  
      ImgTapir: file(relativePath: { eq: "tapirusslider.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }
      ImgTapirSmall: file(relativePath: { eq: "tapirussliderVer.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1800, quality: 90) { ...GatsbyImageSharpFluid } } }  

    }
  `)

  return (
    <Layout className="overflow-hidden">
      <HeadMetadata title="Desarrollo Tapirus - Grupo Libera" />

      <HeroDesarrollos
        fondo={img.fondoOcelot.sharp.fluid}
       fondosmall={img.fondoOcelotSmall.sharp.fluid}
      />

      <section>
          <Img fluid={img.Img01.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img01Small.sharp.fluid} className="lg:hidden" />
      </section> 
      <section>
          <Img fluid={img.Img02.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img02Small.sharp.fluid} className="lg:hidden" />
      </section> 
      <section>
          <Img fluid={img.Img03.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img03Small.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.Img04.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img04Small.sharp.fluid} className="lg:hidden" />
      </section> 
      <section>
          <Img fluid={img.Img05.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img05Small.sharp.fluid} className="lg:hidden" />
      </section> 
      <section>
          <Img fluid={img.Img06.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img06Small.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.Img07.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img07Small.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.Img07.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img07Small.sharp.fluid} className="lg:hidden" />
      </section>      

      <section>
          <Img fluid={img.Img11.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img11Small.sharp.fluid} className="lg:hidden" />
      </section> 
       
      <section>
          <Img fluid={img.Img08.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img08Small.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.Img09.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.Img09Small.sharp.fluid} className="lg:hidden" />
      </section>
      <a href="https://www.santuariodeltapiryucatan.com/" target="_blank">
      <section>
          <Img fluid={img.ImgTapir.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.ImgTapirSmall.sharp.fluid} className="lg:hidden" />
      </section>
      </a>
      

      <div className="bg-black grid items-center">
        <iframe frameborder="no" height="100%" scrolling="auto"
            src="https://crmglibera.com/sistemas_active/crm/if/indexBase.php?clvde=d52%20tc1BABD96"
            className="m-auto manivela">
        </iframe>
        <iframe frameborder="no" height="100%" scrolling="auto"
            src="https://crmglibera.com/sistemas_active/crm/if/indexBase.php?clvde=z0nDPMnT23D79"
            className="m-auto manivela">
        </iframe>
    </div>
    
    <CallToAction
        phone="tel:9993462300"
        whatsapp="https://wa.me/+529993462300"
        email="mailto:info@grupolibera.mx"
      />

    </Layout>
  )
}